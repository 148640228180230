<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Xuất file RFM</h6>
        </template>
        <div class="content">
          <b-row>
            <b-col>
              <b-form-group label="Vui lòng nhập email nhận file">
                <b-input type="email" placeholder="Vui lòng nhập email" required v-model="email"></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button type="button" variant="primary" @click="handleExport()"><i class="fa fa-search"></i> Xuất file</b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Vue from "vue";

Vue.use(Notifications);

const BiRepository = RepositoryFactory.get("Bi");

export default {
  mixins: [Common],
  data() {
    return {
      exportId: "export_rfm_reconcile",
      email: ''
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Xuất file RFM", route: "rfm" },
    ]);
  },
  methods: {
    async handleExport() {
      this.$bus.$emit("show-loading", true);

      if (!this.email) {
        this.notifyAlert("warn", "Vui lòng nhập email");
        this.$bus.$emit("show-loading", false);
        return;
      }

      let params = {
        email: this.email,
        type: "RFM_FUNTAP"
      }

      let response = await BiRepository.getRfmFuntap(params);

      if (response.data.error_code === 1) {
        let errors = response.data.data;

        if (errors.email) {
          this.notifyAlert("warn", errors.email[0]);
        }

        if (errors.type) {
          this.notifyAlert("warn", errors.type[0]);
        }

        this.$bus.$emit("show-loading", false);
        return;
      }

      this.$bus.$emit("show-loading", false);
      let message = response.data.message;
      this.notifyAlert("success", message);
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
  },
  created() {

  },
  watch: {

  },
};
</script>
